import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 736.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,736.000000) scale(0.100000,-0.100000)">
  <path d="M5010 4720 c-191 -40 -358 -132 -490 -270 -86 -90 -169 -229 -190
-321 -7 -30 -17 -67 -22 -84 -5 -16 -12 -78 -15 -136 -10 -176 35 -367 119
-513 36 -60 28 -71 -50 -78 -32 -3 -164 -23 -293 -44 -130 -20 -243 -35 -251
-32 -10 4 -20 31 -28 75 -6 37 -20 111 -30 163 -10 52 -28 151 -40 220 -12 69
-26 141 -30 160 -5 19 -16 80 -25 135 -10 55 -30 170 -46 255 -16 85 -34 184
-40 220 -20 120 -43 215 -53 222 -13 9 -641 11 -672 2 -23 -6 -34 -41 -58
-185 -8 -47 -26 -144 -40 -215 -32 -162 -87 -456 -121 -639 -15 -77 -37 -196
-51 -265 -13 -69 -29 -152 -35 -185 -6 -33 -19 -100 -30 -149 -11 -49 -17 -91
-14 -94 3 -3 80 14 173 37 176 46 201 52 297 71 70 15 81 25 89 88 4 26 18
106 31 177 20 113 50 294 70 433 4 23 10 40 15 37 9 -6 39 -165 70 -375 12
-80 28 -179 35 -220 7 -41 10 -78 6 -83 -4 -4 -50 -18 -102 -31 -145 -37 -320
-85 -364 -100 -22 -8 -101 -33 -175 -56 -74 -23 -142 -50 -151 -59 -9 -10 -28
-85 -48 -191 -52 -274 -58 -308 -80 -420 -39 -199 -41 -212 -36 -220 3 -5 69
31 148 79 182 113 285 170 487 271 127 64 287 140 295 140 2 0 33 13 67 29 35
15 128 54 208 85 80 32 165 65 190 76 25 10 76 27 113 39 38 11 83 27 100 35
18 7 66 23 107 35 41 12 98 30 125 40 28 10 84 26 125 36 41 9 86 21 100 25
88 29 202 60 245 65 28 4 59 10 70 15 11 4 45 13 75 19 30 6 96 21 145 32 50
11 119 24 155 29 36 5 88 14 115 20 145 31 639 93 870 110 77 6 198 15 269 20
167 13 840 13 1020 0 78 -5 211 -15 296 -21 85 -6 241 -22 345 -36 105 -14
217 -29 250 -33 33 -4 72 -11 88 -15 16 -4 27 -3 27 3 0 5 -17 12 -37 15 -21
3 -103 19 -183 36 -153 33 -157 34 -260 50 -36 6 -137 22 -225 36 -88 15 -198
31 -244 36 -56 6 -101 18 -140 38 -318 158 -389 216 -440 354 -29 78 -29 224
-1 282 11 22 19 44 20 49 0 19 53 78 104 116 101 77 240 88 361 30 71 -34 123
-85 161 -160 29 -55 29 -56 34 -290 l5 -235 270 0 270 0 3 240 c2 228 1 244
-22 330 -33 118 -49 165 -58 165 -5 0 -8 5 -8 11 0 26 -80 138 -150 210 -163
167 -379 264 -621 276 -210 11 -449 -65 -610 -194 -35 -28 -149 -144 -174
-178 -44 -58 -102 -181 -126 -265 -34 -121 -34 -343 0 -474 26 -99 46 -152 61
-161 15 -9 12 -52 -3 -58 -7 -3 -82 -2 -167 2 -84 4 -341 4 -569 0 -274 -5
-420 -4 -428 3 -7 5 -44 26 -82 46 -106 56 -195 140 -232 220 -27 56 -32 82
-36 167 -5 95 -4 103 24 165 53 117 144 189 264 211 128 23 235 -15 327 -115
68 -74 79 -120 89 -376 l8 -225 265 0 265 0 0 270 0 270 -33 94 c-18 52 -54
128 -79 169 -50 79 -179 217 -253 268 -197 138 -474 194 -710 144z"/>
<path d="M6886 3169 c-201 -9 -266 -17 -266 -33 0 -13 169 -117 269 -165 35
-17 68 -34 75 -39 6 -5 58 -34 116 -64 278 -146 307 -163 346 -204 19 -19 46
-53 61 -76 25 -37 28 -50 28 -127 0 -76 -4 -93 -30 -145 -32 -63 -111 -142
-169 -170 -20 -9 -62 -21 -94 -26 -151 -25 -296 53 -369 200 -25 51 -27 67
-33 210 l-5 155 -130 5 c-71 3 -195 3 -275 0 l-145 -5 0 -175 c1 -226 21 -314
112 -475 39 -69 54 -87 163 -195 139 -138 280 -210 476 -244 194 -34 399 -4
576 85 304 153 488 451 488 791 0 135 -19 219 -76 345 -37 81 -169 225 -282
305 -45 32 -64 39 -134 48 -91 11 -456 11 -702 -1z"/>
<path d="M5730 3038 c-87 -14 -344 -66 -470 -94 -63 -14 -127 -28 -142 -31
-43 -8 -53 -28 -20 -43 15 -7 36 -17 47 -23 11 -6 36 -19 55 -29 163 -87 214
-120 263 -172 62 -67 80 -115 75 -201 -6 -98 -40 -167 -118 -238 -70 -65 -131
-89 -229 -89 -96 -1 -157 22 -229 87 -49 45 -66 69 -91 129 -29 71 -31 81 -31
210 0 90 -4 137 -11 142 -7 4 -120 6 -252 5 -195 -1 -245 -5 -270 -17 l-30
-16 6 -161 c6 -192 28 -291 90 -412 109 -212 315 -389 532 -459 73 -23 218
-46 289 -46 131 1 298 41 418 101 283 142 470 421 485 722 3 62 1 149 -5 194
-12 89 -60 227 -92 263 -11 13 -20 27 -20 31 0 5 -32 43 -71 84 -78 81 -76 81
-179 63z"/>
<path d="M3807 2485 c-60 -24 -114 -48 -120 -53 -7 -5 -30 -16 -52 -24 -94
-37 -390 -183 -550 -271 -276 -152 -779 -486 -761 -505 9 -9 470 -2 475 7 4 6
15 68 25 138 10 70 21 142 25 158 l7 30 317 3 c174 1 317 1 317 0 0 -13 51
-307 56 -320 3 -11 16 -19 32 -19 86 -5 508 4 515 10 4 5 1 39 -7 77 -9 38
-23 112 -31 164 -9 52 -29 163 -45 245 -16 83 -39 207 -51 278 -13 71 -27 127
-33 127 -6 -1 -60 -21 -119 -45z"/>
<path d="M380 98 c-36 -4 -87 -15 -115 -24 -47 -15 -135 -61 -135 -70 0 -2
141 -4 313 -4 l312 1 -40 24 c-95 59 -220 86 -335 73z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
